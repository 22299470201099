import React from "react"
import Layout from "../layouts"
import { HeroServices, InfoHeader, InfoTextHeader, InfoBody, TextBox, InfoText, ServicesInfo, ServicesNav} from '../styles/Global'


export default function Home() {
    return (
        <Layout>
            <HeroServices>
                <InfoHeader>
                    <InfoTextHeader>
                        Our Services
                    </InfoTextHeader>
                </InfoHeader>
                <InfoBody services>
                    <ServicesInfo>
                        <TextBox>
                            <InfoText>
                                Construction
                            </InfoText>
                            <InfoText>
                                Sweeping
                            </InfoText>
                        </TextBox>
                        <TextBox>
                            <InfoText light>
                                • Regular or final cleanup 
                            </InfoText>
                            <InfoText light>
                                • Trash removal
                            </InfoText>
                            <InfoText light>
                                • Sweeping
                            </InfoText>
                        </TextBox>
                        <ServicesNav to={`/contact`}>
                            Contact Us
                        </ServicesNav>
                    </ServicesInfo>
                    <ServicesInfo shadowed>
                        <TextBox>
                            <InfoText>
                                Daily/Weekly Lot
                            </InfoText>
                            <InfoText>
                                Porter Services
                            </InfoText>
                        </TextBox>
                        <TextBox>
                            <InfoText light>
                                • Lot Sweeping 
                            </InfoText>
                            <InfoText light>
                                • Pressure Washing
                            </InfoText>
                            <InfoText light>
                                • Trash Removal
                            </InfoText>
                            <InfoText light>
                                • Lot Detailing
                            </InfoText>
                        </TextBox>
                        <ServicesNav to={`/contact`}>
                            Contact Us
                        </ServicesNav>
                    </ServicesInfo>     
                    <ServicesInfo>
                        <TextBox>
                            <InfoText>
                                Parking Lot
                            </InfoText>
                            <InfoText>
                                Sweeping
                            </InfoText>
                        </TextBox>
                        <TextBox>
                            <InfoText light>
                                • Professional Lot Sweeping
                            </InfoText>
                            <InfoText light>
                                • Dedicated Detailer
                            </InfoText>
                        </TextBox>
                        <ServicesNav to={`/contact`}>
                            Contact Us
                        </ServicesNav>
                    </ServicesInfo>                    
                </InfoBody>
            </HeroServices>
        </Layout>
        );
    }